import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home'),
      meta: {
        isOnNavigation: true,
        label: 'Accueil',
        order: 1
      }
    },
    {
      path: '/coach',
      name: 'Coach',
      component: () => import('./views/Coach'),
      meta: {
        isOnNavigation: true,
        label: 'Les coachs',
        order: 3
      }
    },
    {
      path: '/blog',
      name: 'BlogHome',
      component: () => import('./views/BlogHome'),
      meta: {
        isOnNavigation: true,
        label: 'Blog',
        order: 4
      }
    },
    {
      path: '/blog/:uid',
      name: 'BlogPost',
      component: () => import('./views/BlogPost')
    },
    {
      path: '/courses',
      name: 'Courses',
      component: () => import('./views/Courses'),
      meta: {
        isOnNavigation: true,
        label: 'Programmes',
        order: 2
      }
    },
    {
      path: '/courses/:uid',
      name: 'CourseItem',
      component: () => import('./views/CourseItem')
    },
    {
      path: '/freeTrial/:uid',
      name: 'FreeTrial',
      component: () => import('./views/CourseFreeVideos'),
      beforeEnter: (to, from, next) => {
        const localStorageElt = localStorage.getItem('hasFilledTheForm')
        if (localStorageElt === 'true') {
          return next()
        }
        return next({ name: 'TrialForm', query: { courseUid: to.params.uid } })
      }
    },
    {
      path: '/trialForm',
      name: 'TrialForm',
      component: () => import('./views/TrialForm')
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: () => import('./views/FAQ'),
      meta: {
        isOnNavigation: true,
        label: 'FAQ',
        order: 5
      }
    }
  ]
})

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    card: [],
    windowWidth: window.innerWidth
  },
  mutations: {
    ADD_ITEM_TO_CARD(state, item) {
      const itemOnCardId = state.card.map((item) => item.id)
      if (!itemOnCardId.includes(item.id)) {
        state.card.push(item)
      }
    },
    REMOVE_ITEM_FROM_CARD(state, itemId) {
      const itemIndex = state.card.findIndex((item) => item.id === itemId)
      state.card.splice(itemIndex, 1)
    },
    SET_WINDOW_WIDTH(state) {
      state.windowWidth = window.innerWidth
    }
  }
})

export default store

import Vue from 'vue'
import App from './App'
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver'
import router from './router'
import VueMeta from 'vue-meta'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

import '@/styles/main.scss'

Vue.use(VueMeta)

// Vue.use(PrismicVue, {
//   endpoint: process.env.VUE_APP_PRISMIC_ENDPOINT,
//   apiOptions: {
//     accessToken: process.env.VUE_APP_PRISMIC_KEY
//   },
//   linkResolver
// })
Vue.use(PrismicVue, {
  endpoint: 'https://blast.cdn.prismic.io/api/v2',
  apiOptions: {
    accessToken:
      'MC5ZSDFia0JNQUFQa055VHNh.JEHvv71r77-9Vhfvv73vv70o77-977-977-9IV8iUe-_ve-_ve-_ve-_vQIwWVsMADMqJ--_ve-_vQ'
  },
  linkResolver
})

new Vue({
  el: '#app',
  router,
  store,
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  render: (h) => h(App)
})

<template lang="pug">
#app
  Header
  router-view
  Footer
  transition(name='fade')
    subscribe-newsletter-popup(v-if='isNewsletterPopupOpen', @close:banner='closeNewsletterPopup')
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer'),
    Header: () => import('@/components/Header/index'),
    ContactBanner: () => import('@/components/ContactBanner'),
    SubscribeNewsletterPopup: () => import('@/components/SubscribeNewsletterPopup')
  },
  data() {
    return {
      isNewsletterPopupOpen: false
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    if (!Cookies.get('disableNewsletterPopup')) {
      setTimeout(() => (this.isNewsletterPopupOpen = true), 5000)
    }
  },
  methods: {
    handleWindowResize() {
      this.$store.commit('SET_WINDOW_WIDTH')
    },
    closeNewsletterPopup() {
      Cookies.set('disableNewsletterPopup', true, { expires: 7 })
      this.isNewsletterPopupOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
